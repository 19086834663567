import LayoutAdmin from "@/layouts/LayoutAdmin";
export default {
  data() {
    return {
      search: "",
      loadingTb: false,
      isActive: [],
      options: null,
      summary: [],
      istableLoading:false,
      tablePorsi:[],
    //   tablePorsi:[
    //     {
    //         "kdbalai": 1,
    //         "nmbalai": "PUSAT",
    //         "pagu": 115000000000,
    //         "span": 148873489957,
    //         "childs": [
    //             {
    //                 "kode": "400696",
    //                 "kdprogram": "FC",
    //                 "kdbalai": 1,
    //                 "nmbalai": "PUSAT",
    //                 "kdsatker": "400696",
    //                 "nmsatker": "DIREKTORAT BENDUNGAN DAN DANAU ",
    //                 "pagu": 115000000000,
    //                 "span": 148873489957,
    //                 "childs": [
    //                     {
    //                         "kode": "5039",
    //                         "kdprogram": "FC",
    //                         "kdbalai": 1,
    //                         "nmbalai": "PUSAT",
    //                         "kdsatker": "400696",
    //                         "nmsatker": "DIREKTORAT BENDUNGAN DAN DANAU ",
    //                         "kdgiat": "5039",
    //                         "nmgiat": "Pembangunan Bendungan, Danau, dan Bangunan Penampung Air Lainnya",
    //                         "pagu": 115000000000,
    //                         "span": 148873489957,
    //                         "childs": [
    //                             {
    //                                 "kode": "5039.ABF",
    //                                 "kdprogram": "FC",
    //                                 "kdbalai": 1,
    //                                 "nmbalai": "PUSAT",
    //                                 "kdsatker": "400696",
    //                                 "nmsatker": "DIREKTORAT BENDUNGAN DAN DANAU ",
    //                                 "kdgiat": "5039",
    //                                 "nmgiat": "Pembangunan Bendungan, Danau, dan Bangunan Penampung Air Lainnya",
    //                                 "kdoutput": "ABF",
    //                                 "nmoutput": "Kebijakan Bidang Sarana dan Prasarana",
    //                                 "pagu": 115000000000,
    //                                 "span": 148873489957,
    //                                 "childs": [
    //                                     {
    //                                         "kdprogram": "FC",
    //                                         "nmprogram": "Program Ketahanan Sumber Daya Air",
    //                                         "kdbalai": 1,
    //                                         "nmbalai": "PUSAT",
    //                                         "kdsatker": "400696",
    //                                         "nmsatker": "DIREKTORAT BENDUNGAN DAN DANAU ",
    //                                         "kdgiat": "5039",
    //                                         "nmgiat": "Pembangunan Bendungan, Danau, dan Bangunan Penampung Air Lainnya",
    //                                         "kdoutput": "ABF",
    //                                         "nmoutput": "Kebijakan Bidang Sarana dan Prasarana",
    //                                         "register": "1F1NDHGA",
    //                                         "nmregister": "Engineering Services Project (ESP)",
    //                                         "pagu": 115000000000,
    //                                         "span": 148873489957
    //                                     }
    //                                 ]
    //                             }
    //                         ]
    //                     }
    //                 ]
    //             }
    //         ]
    //     }
    // ],
      isShow: true,
    };
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.getSummary();
  },
  components: {
  },

  computed: {
    
  },
  methods: {
    getSummary() {
    
      let filter = [];     
      filter.push("saktiEventId=" + this.$route.query.eventId);

      filter = "?" + filter.join("&");

      console.log(filter);

      this.G_GetAny("adk/resume/item/invalid-pagu-minus" + filter).then((res) => {
       
        this.tablePorsi = res.data.content;
        this.istableLoading = false;
      });
     
    },
    cariData() {
      this.getSummary();
    },

  },
};
